const sortingOptions = {
  recommended: 'premium',
  priceLow: 'price',
  priceHigh: '-price',
  customerRatingHigh: 'tripadvisor_rating',
};

export default sortingOptions;

export { sortingOptions };
