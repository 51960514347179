import dayjs from 'dayjs'

import {
  DATE_FORMAT,
  buildSearchPayload,
  buildOptions,
  formatDate,
  getDuration,
  getDestinations,
  prepareRoomsForURL,
  isDateValid,
  evaluatePeoplePerRoom,
  evaluateRoomsHaveEmptyAgeValues,
  parseRoomsFromURL,
  hasQueryAllRequiredSearchParams,
  defaultUrlParams,
  TEMPERATURE_MIN,
  TEMPERATURE_MAX,
  getCategories,
} from '@/stores/utils/charter-packages'
import fetchStatus from '@/constants/fetch-status'
import searchModes from '@/constants/search-modes'
import localStorageKeys from '@/constants/local-storage-keys'
import sortingOptions from '@/constants/sorting-options'
import { useLocaleStore } from "@/stores/locale";
import { useCharterStore } from "@/stores/charter";
import { DATEPICKER_DATE_FORMAT } from '@/constants/date'

const LAST_MINUTE_TRIP_DURATION = 10

const INIT_CALENDAR_SUGGESTIONS = {
  direct: null,
  flights: null,
}

export const DEFAULT_DURATION = {
  value: {
    value: 7,
    maxValue: 9,
    nameValue: 1
  },
  custom: [2, 30]
}

const defaultState = () => ({
  isDirty: false,
  hasSearched: false,

  destinations: [],
  categories: [],

  calendarSuggestionsFetchStatus: fetchStatus.idle,
  calendarSuggestions: INIT_CALENDAR_SUGGESTIONS,
  calendarSuggestionsForDepartureDate: INIT_CALENDAR_SUGGESTIONS,

  timeout: 60 * 1000,
  selectedAirport: null,
  selectedDuration: DEFAULT_DURATION,
  selectedCategories: null,
  selectedDestinations: [],
  // selectedDestinationsL1 lists the names of the destination groups whose destinations are all selected
  selectedDestinationsL1: [],
  rooms: [{
    Adults: 2,
    Children: 0,
    ChildAges: []
  }],
  selectedStartDateRange: 0,
  // NOTE when set, can be a date string or an object with min and max date strings
  selectedDateRangeStartDate: '',
  // NOTE same type as selectedDateRangeStartDate
  selectedDateRangeEndDate: '',
  selectedDateRangeDuration: 7,
  lastminute: false,
  temperatureOptions: [
    {
      id: 1,
      min: 30,
      max: TEMPERATURE_MAX,
      label: 'charterFilterTemperatureAtLeast'
    },
    {
      id: 2,
      min: 25,
      max: 30,
      label: 'charterFilterTemperatureSpan'
    },
    {
      id: 3,
      min: 20,
      max: 25,
      label: 'charterFilterTemperatureSpan'
    },
    {
      id: 4,
      min: TEMPERATURE_MIN,
      max: 20,
      label: 'charterFilterTemperatureAtMost'
    }
  ],
  durations: [
    {
      value: 7,
      maxValue: 9,
      nameValue: 1
    },
    {
      value: 10,
      maxValue: 13,
      nameValue: '1½'
    },
    {
      value: 14,
      maxValue: 16,
      nameValue: 2
    },
    {
      value: 21,
      maxValue: 23,
      nameValue: 3
    },
    {
      value: 28,
      maxValue: 30,
      nameValue: 4
    }
  ],

  loading: false,
  error: false,
  nothingFound: false,
  timedout: false,

  modalRoomTypes: [],
  modalHotelId: null,

  selectedRooms: [],

  packages: [],
  calendarPackages: [],
  activeSlideDate: null,
  dateOptions: {},

  stateObjects: [],

  filterStopover: 0,
  filterRating: [],
  filterTripadvisor: 1,
  filterBoard: null,
  filterTemperature: [],
  filterText: '',
  filterTags: [],
  filterZones: [],
  filterCharterTags: [],

  filters: {},

  sortBy: sortingOptions.recommended,
  charterSearchQuery: null,
})

export const useCharterPackagesStore = defineStore('charterPackages', {
  state: () => defaultState(),
  getters: {
    getCalendarPackagesWithBestPrice (state) {
      const bestPrice = Math.min(...state.calendarPackages.filter(pkg => typeof pkg.price === 'number').map(pkg => pkg.price))

      return state.calendarPackages.map(pkg => ({ ...pkg, bestPrice: bestPrice === pkg.price }))
    },

    isDateRangeSelected: state => Boolean(state.selectedDateRangeStartDate && state.selectedDateRangeEndDate),

    getSearchOptions(state) {
      return function (options) {
      // NOTE removed getters from function getSearchOptions: (state, getters), buildSearchPayload(state, options, getters) wont work in pinia
        const payload = buildSearchPayload(state, options)
        return payload
      }
    },

    getURLParameters: (state) => (params) => {
      const options = []

      buildOptions(options, 'airport', params.selectedAirport?.airport)

      if (params.searchMode === searchModes.calendar) {
        buildOptions(options, 'date', (params.selectedStartDate?.selectedDate && !params.lastminute
          ?
          formatDate(params.selectedStartDate.selectedDate)
          :
          null))
        buildOptions(options, 'range', !params.lastminute ? params.selectedStartDateRange : null)

        /**
         * Calendar outputs same when "other travel length" isn't used
         * Set to specified date to make PackageSearchBar date dropdown to function and highlight properly
         */
        if (!!params.selectedDuration?.min && params.selectedDuration?.min === params.selectedDuration?.max) {
          const endDate = dayjs(params.selectedStartDate.selectedDate)
            // -1 because departure day is also counted
            .add(params.selectedDuration.min - 1, 'days')
            .format('YYYY-MM-DD')
          buildOptions(options, 'date_end', formatDate(endDate))
        } else {
          buildOptions(options, 'duration', getDuration(state, params))
        }
      } else {
        buildOptions(options, 'date',
          (formatDate(params.selectedDateRangeStartDate))
        )
        if (state.selectedDateRangeDuration) {
          // non-flex dates
          if (state.selectedDateRangeDuration.value === 'custom') {
            // NOTE handle edge case of user loading page with duration=n,m
            buildOptions(options, 'duration', getDuration(state, { selectedDuration: state.selectedDateRangeDuration }))
          } else {
            buildOptions(options, 'duration', state.selectedDateRangeDuration)
          }
        } else {
          // flex dates
          buildOptions(options, 'date_end', formatDate(params.selectedDateRangeEndDate))
        }
      }

      if (params.selectedCategories?.length && Array.isArray(params.selectedCategories)) {
        const categories = getCategories(params.selectedCategories)

        buildOptions(options, 'categories', categories)
      } else if (params.selectedDestinations?.length || params.selectedDestinationsL1?.length) {
        const destinations = getDestinations(state, params)

        buildOptions(options, 'countries', destinations.countries)
        buildOptions(options, 'destinations', destinations.destinations)
      }

      buildOptions(options, 'stopover', params.stopover)
      buildOptions(options, 'hotel', params.hotel)
      buildOptions(options, 'rating', params.rating ? params.rating.join(',') : null)
      buildOptions(options, 'ta', params.ta)
      buildOptions(options, 'board', params.board)
      buildOptions(options, 'text', params.text)
      buildOptions(options, 'tags', params.tags ? params.tags.join(',') : null)
      buildOptions(options, 'chartertags', params.charterTags ? params.charterTags.join(',') : null)
      buildOptions(options, 'temperature', params.temperature ? params.temperature.join(',') : null)

      if (params.rooms) {
        const rooms = prepareRoomsForURL(params)
        rooms.forEach(({ key, value }) => {
          buildOptions(options, key, value)
        })
      }

      buildOptions(options, 'lastminute', params.lastminute)

      return options.join('&')
    },
    /**
     * If the query arg is not sufficient to complete a payload for the search endpoint, return a merged object that is
     */
    getMergedWithRequiredSearchUrlParams: () => (query = {}) => {
      const localeStore = useLocaleStore()

      if (hasQueryAllRequiredSearchParams(query)) {
        return query
      }

      const merged = {
        ...defaultUrlParams(localeStore.locale),
        ...query,
      }

      if (query.date_end) {
        merged.date_end = query.date_end
        delete merged.duration
        if (query.duration) {
          merged.duration = query.duration
        }
      }

      if (query.destinations || query.countries) {
        if (query.destinations) {
          merged.destinations = query.destinations
        }
        if (query.countries) {
          merged.countries = query.countries
        }
        delete merged.categories
        if (query.categories) {
          merged.categories = query.categories
        }
      }

      return merged
    },

    getCharterTag: (state) => (id) => {
      return (state.filters?.charter_tags || []).find(tag => tag.id === id)
    },

    getTemperatureOptions (state) {
      return state.temperatureOptions
    },

    getTemperatureRange(state){
      // Technically, we're cutting corners here. But it's a conscious decision for now. Checkboxes are sub-optimal.
      const selectedOptions = (state.filterTemperature || []).length > 0
        ? (state.filterTemperature || [])
          .map(x => state.temperatureOptions.find(({ id }) => x === id))
          .filter(Boolean)
        : state.temperatureOptions
      const numericSpan = selectedOptions
        .map(x => [x.max, x.min])
        .flatMap(x => x)

      return {
        max: Math.max(...numericSpan),
        min: Math.min(...numericSpan)
      }
    },

    temperatureRangeIsDefault() {
      const { max, min } = this.getTemperatureRange
      return max === TEMPERATURE_MAX && min === TEMPERATURE_MIN
    },

    airportDestinationIds: () => {
      const searchWidgetsStore = useSearchWidgetsStore()
      const rootStore = useRootStore()
      const stagedAirport = searchWidgetsStore.stagedAirport
      const charterDestinations = rootStore.charterDestinations

      if (!charterDestinations) {
        return []
      }

      return stagedAirport
        ? charterDestinations
          .flatMap(d => d.destinations)
          .filter(d => d.avalible_from_airports.includes(stagedAirport.airport))
          .map(d => d.destination_id)
        : []
    },

    destinationIdsByAirport: () => (airport) => {
      const searchWidgetsStore = useSearchWidgetsStore()
      const rootStore = useRootStore()
      const charterDestinations = rootStore.charterDestinations
      const stagedAirport = airport || searchWidgetsStore.stagedAirport

      if (!charterDestinations) {
        return []
      }

      return stagedAirport
        ? charterDestinations
          .flatMap(d => d.destinations)
          .filter(d => d.avalible_from_airports.includes(stagedAirport.airport))
          .map(d => d.destination_id)
        : []
    },

    groupedDestinationsForAirport() {
      const searchWidgetsStore = useSearchWidgetsStore()
      const groups = {}
      const stagedAirport = searchWidgetsStore.stagedAirport

      if (!stagedAirport || !this.airportDestinationIds.length) {
        return this.groupedDestinations
      }

      this.visibleDestinations
        .filter(d => this.airportDestinationIds.includes(d.destination_id))
        .forEach((d) => {
          groups[d.country] = groups[d.country] || {
            name: d.country,
            destinations: []
          }
          groups[d.country].destinations.push(d)
        })

      return Object.values(groups)
    },

    groupedDestinations: (state) => {
      const groups = {}

      state.destinations
        .filter(d => d.visible)
        .forEach((d) => {
          groups[d.country] = groups[d.country] || {
            name: d.country,
            destinations: []
          }
          groups[d.country].destinations.push(d)
        })

      return Object.values(groups)
    },

    categoriesForAirport(state) {
      const searchWidgetsStore = useSearchWidgetsStore()
      const stagedAirport = searchWidgetsStore.stagedAirport

      if (!stagedAirport || !this.airportDestinationIds.length) {
        return state.categories
      }

      const categoriesForDestinations = state.destinations
        .filter(dest => this.airportDestinationIds.includes(dest.destination_id))
        .flatMap(dest => dest.categories)

      const uniqueCategoryIds = Array.from(new Set(categoriesForDestinations)).filter(id => !isNaN(parseInt(id))).map(id => parseInt(id))

      return state.categories.filter(cat => uniqueCategoryIds.includes(cat.id))
    },

    visibleDestinations: state => state.destinations.filter(d => d.visible),

    visibleDestinationsL1: state => Array.from(new Set(state.destinations.filter(d => d.visible).map(d => d.country))),

    getBoard: ({ filters: { boards }, filterBoard }) => {
      // Meal types. All Inclusive / Just The Room, and so on.
      return boards && boards.length > 0 ? boards[5 - filterBoard] : ''
    },

    getNoOfPassengers: ({ rooms }) => (rooms || []).reduce((acc, { Adults, Children }) => acc + Adults + Children, 0) || 0,

    overMaxNoOfPassengers() {
      return this.getNoOfPassengers >= 9
    },

    roomsHaveEmptyAgeValues: ({ rooms }) => evaluateRoomsHaveEmptyAgeValues(rooms),

    peoplePerRoom: ({ rooms }) => evaluatePeoplePerRoom(rooms),

    getSelectedDuration: ({ selectedDuration }) => {
      if (
        Object.prototype.toString.call(selectedDuration) === '[object Object]' &&
        selectedDuration?.custom &&
        selectedDuration?.value &&
        selectedDuration?.value?.value
      ) {
        return selectedDuration
      } else {
        return DEFAULT_DURATION
      }
    },

    getSortingOptions: () => ([{
      id: sortingOptions.recommended,
      translationKey: 'charterSortRecommended'
    }, {
      id: sortingOptions.priceLow,
      translationKey: 'charterSortLowestPrice'
    }, {
      id: sortingOptions.priceHigh,
      translationKey: 'charterSortHighestPrice'
    }, {
      id: sortingOptions.customerRatingHigh,
      translationKey: 'charterSortHighestCustomerRating'
    }]),

    getCharterSearchQuery: (state) => {
      return state.charterSearchQuery
    },
  },
  actions: {
    SET_CHARTER_SEARCH_QUERY (query) {
      this.charterSearchQuery = query
    },

    //old mutations
    SET_SEARCHOPTIONS (options) {
      this.selectedAirport = options.selectedAirport || this.selectedAirport
      this.selectedCategories = options.selectedCategories || this.selectedCategories
      this.selectedDestinations = options.selectedDestinations || this.selectedDestinations
      this.selectedDestinationsL1 = options.selectedDestinationsL1 || this.selectedDestinationsL1
      this.rooms = options.rooms || this.rooms
      this.selectedDuration = options.selectedDuration || this.selectedDuration
      this.selectedStartDate = options.selectedStartDate || this.selectedStartDate
      this.selectedStartDateRange = options.selectedStartDateRange
    },

    RESET_SEARCHOPTIONS () {
      const dState = defaultState()

      this.selectedAirport = dState.selectedAirport
      this.selectedCategories = dState.selectedCategories
      this.selectedDestinations = dState.selectedDestinations
      this.selectedDestinationsL1 = dState.selectedDestinationsL1
      this.rooms = dState.rooms || this.rooms
      this.selectedDuration = dState.selectedDuration
      this.selectedStartDate = dState.selectedStartDate
      this.selectedStartDateRange = dState.selectedStartDateRange
      this.selectedDateRangeDuration = dState.selectedDateRangeDuration
      this.selectedDateRangeEndDate = dState.selectedDateRangeEndDate
    },

    SET_NOTHINGFOUND (nothingFound) {
      this.nothingFound = nothingFound
    },

    SET_MODALROOMTYPES ( data) {
      this.modalRoomTypes = data
    },

    SET_MODALHOTELID (data) {
      this.modalHotelId = data
    },

    SET_DESTINATIONS (data) {
      this.destinations = data
    },

    SET_CATEGORIES (data) {
      this.categories = data
    },

    SET_STARTDATE (data) {
      this.startDate = data
    },

    SET_SELECTED_DATE_RANGE_START_DATE (data) {
      this.isDirty = true
      this.selectedDateRangeStartDate = isDateValid(data) ? formatDate(data) : data
    },

    SET_SELECTED_DATE_RANGE_END_DATE (data) {
      this.isDirty = true
      this.selectedDateRangeEndDate = isDateValid(data) ? formatDate(data) : data
    },

    SET_SELECTED_DATE_RANGE_DURATION (data) {
      this.selectedDateRangeDuration = data
    },

    SET_TIMEDOUT (data) {
      this.timedout = data
    },

    RESET_STATEOBJECT () {
      this.stateObjects = []
    },

    RESET_DATEOPTIONS () {
      this.dateOptions = {}
    },

    SET_STATEOBJECT ({ stateObject, step }) {
      this.stateObjects[step] = stateObject
    },

    SET_SELECTED_STARTDATERANGE (data) {
      this.selectedStartDateRange = data
    },

    SET_SELECTED_AIRPORT (data) {
      this.isDirty = true
      this.selectedAirport = data
    },

    SET_SELECTED_DURATION (data) {
      this.selectedDuration = data
    },

    SET_SELECTED_CATEGORIES (data) {
      this.isDirty = true
      this.selectedCategories = data
    },

    SET_SELECTED_DESTINATIONS (data) {
      this.isDirty = true
      this.selectedDestinations = data
    },

    SET_SELECTED_DESTINATIONSL1 (data) {
      this.isDirty = true
      this.selectedDestinationsL1 = data
    },

    SET_RECOMMENDED (flights) {
      this.recommendedFlights = flights
    },

    SET_LOADING (loading) {
      this.loading = loading
    },

    SET_MPIS (data) {
      this.mpis = data
    },

    SET_SELECTED_ROOMS (data) {
      this.selectedRooms = data
    },

    SET_HOTELERROR (error) {
      this.hotelError = error
    },

    SET_ERROR (error) {
      this.error = error
    },

    SET_ROOMS (data) {
      this.isDirty = true
      this.rooms = [...(data || [])]
    },

    SET_PACKAGES (data) {
      this.packages = data
    },

    SET_CALENDAR_PACKAGES (data) {
      this.calendarPackages = data
    },

    SET_CALENDAR_PACKAGE_BY_DATE ({ result, date }) {
      const packages = [...this.calendarPackages]

      const index = packages.findIndex(d => d.date === date)

      packages[index] = result

      this.calendarPackages = packages
    },

    UNSHIFT_CALENDAR_PACKAGE_WITH_DATE (date) {
      const packages = [...this.calendarPackages]

      this.calendarPackages = [
        ...packages,
        {
          loading: true,
          date
        }
      ]
    },

    SET_ACTIVE_SLIDE_DATE (data) {
      this.activeSlideDate = data
    },

    SET_DATEOPTIONS ({ index, data }) {
      this.dateOptions[index] = data;
    },

    SET_FILTERS (data) {
      this.filters = data
      this.filters.boards = this.filters.boards.slice().reverse()
    },

    SET_FILTER_RATING (data) {
      this.filterRating = data.map(x => +x)
    },

    SET_FILTER_TRIPADVISOR (data) {
      this.filterTripadvisor = data
    },

    SET_FILTER_BOARD (data) {
      /**
        * NOTE handling these cases
        * 1. data is a string with a single number
        * 2. data is a string with a comma-separated list of numbers (query params)
        * 3. data is a number
        * Anything else will set the filter to null (its default value)
       * */
      const isValid = val => !isNaN(parseInt(val))

      if (typeof data === 'string' && /,/g.test(data)) {
        const parts = data.split(',').filter(val => isValid(val)).map(val => parseInt(val))
        this.filterBoard = Math.min(...parts) || null
        return
      }

      this.filterBoard = isValid(data) ? parseInt(data) : null
    },

    SET_FILTER_TEXT (data) {
      this.filterText = data
    },

    SET_FILTER_TAGS (data) {
      this.filterTags = data
    },

    SET_FILTER_STOPOVER (data) {
      this.filterStopover = data
    },

    SET_FILTER_ZONES (data) {
      this.filterZones = data
    },

    SET_FILTER_CHARTER_TAGS (data) {
      this.filterCharterTags = data
    },

    TOGGLE_FILTER_CHARTER_TAG (tagId) {
      if (this.filterCharterTags.includes(tagId)) {
        this.filterCharterTags = this.filterCharterTags.filter(id => id !== tagId)
      } else {
        this.filterCharterTags.push(tagId)
      }
    },

    TOGGLE_FILTER_TEMPERATURE (temperatureId) {
      if (this.filterTemperature.includes(temperatureId)) {
        this.filterTemperature = this.filterTemperature.filter(id => id !== temperatureId)
      } else {
        this.filterTemperature.push(temperatureId)
      }
    },

    SET_FILTER_TEMPERATURE (temperatures) {
      this.filterTemperature = [...temperatures.map(x => parseInt(x))]
    },

    RESET_FILTERS () {
      const dState = defaultState()

      this.filterStopover = 2
      this.filterBoard = 1

      this.filterRating = dState.filterRating
      this.filterTripadvisor = dState.filterTripadvisor
      this.filterTemperature = dState.filterTemperature
      this.filterText = dState.filterText
      this.filterTags = dState.filterTags
      this.filterZones = dState.filterZones
      this.filterCharterTags = dState.filterCharterTags
    },

    SET_FILTER_TAG (tag) {
      const i = this.filterTags.findIndex(z => z === tag)

      if (i > -1) {
        this.filterTags.splice(i, 1)
        return
      }

      this.filterTags.push(tag)
    },

    SET_CALENDAR_SUGGESTIONS (data) {
      this.calendarSuggestions = data
    },

    SET_CALENDAR_SUGGESTIONS_FOR_DEPARTURE_DATE (data) {
      this.calendarSuggestionsForDepartureDate = data
    },

    SET_CALENDAR_SUGGESTIONS_FETCH_STATUS (data) {
      this.calendarSuggestionsFetchStatus = data
    },

    SET_LASTMINUTE (data) {
      this.lastminute = data
    },

    SET_SORT_BY (data) {
      this.sortBy = data
    },

    SET_IS_DIRTY (data) {
      this.isDirty = data
    },

    setHasSearched () {
      this.hasSearched = true
    },

    //old Actions
    async getDestinations () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (this.destinations?.length) {
        return
      }

      try {
        const { data: destinations } = await apiFetch(`/${locale}/charter/packages/destination_list_with_categories`)
        this.SET_DESTINATIONS(destinations.destinations)
        this.SET_CATEGORIES(destinations.categories)
      } catch {
        // TODO: add catch if needed
      }
    },

    async getFilters () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (Object.keys(this.filters).length > 0) {
        return
      }

      const { data: result } = await apiFetch(`/${locale}/charter/packages/filter_options`)

      this.SET_FILTERS(deepClone(result))
    },

    async search (options) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (options) {
        this.SET_SEARCHOPTIONS(deepClone(options))
      }
      this.RESET_DATEOPTIONS()
      this.SET_TIMEDOUT(false)
      this.SET_NOTHINGFOUND(false)
      this.SET_HOTELERROR(false)
      this.SET_MODALHOTELID(null)
      this.SET_MODALROOMTYPES([])

      this.SET_ERROR(false)

      const isValidNonFlex = isDateValid(this.selectedDateRangeStartDate) && this.selectedDateRangeDuration
      const isValidFlex = isDateValid(this.selectedDateRangeStartDate) && isDateValid(this.selectedDateRangeEndDate)

      if (!isValidNonFlex && !isValidFlex) {
        this.SET_ERROR(true)
        return false
      }

      const searchOptions = this.getSearchOptions(options)

      if (!searchOptions) {
        this.SET_ERROR(true)
        this.SET_LOADINGFLIGHTS(false)
        this.SET_LOADING(false)
        this.SET_SEARCHING_ALTERNATIVES(false)
        return false
      }

      this.SET_LOADING(true)
      // if (window.cab_1) {
      //   window.cab_1.abort()
      // }

      // window.cab_1 = new AbortController()
      // abortTimeout(window.cab_1, this.timeout)

      try {
        const { data: result } = await apiFetch(`/${locale}/charter/packages/offers/search`, {
          method: 'POST',
          body: searchOptions,
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
        })
        // TEST: if no results, show nothing found
        if (!result) {
          this.SET_NOTHINGFOUND(true)
        }

        const transformedResult = result.map((r, i) => ({
          ...r,
          search_id: r.id,
          id: `${i}_${r.hotel_code}`
        }))
        this.SET_PACKAGES(transformedResult)
        this.SET_LOADING(false)
        this.SET_IS_DIRTY(false)
        this.setHasSearched()

        return transformedResult
      } catch (e) {
        console.log('search error', e)
        //TODO: check if this is working
        if (e.name === 'AbortError') {
          return
        }

        this.SET_ERROR(true)
        return false
      }
    },

    async searchDate (options) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const searchOptions = this.getSearchOptions()

        searchOptions.mode = options.mode
        searchOptions.departureDate = {
          min: options.minDate,
          max: options.maxDate,
        }

        if (this.filterZones.length) {
          searchOptions.zoneNames = this.filterZones.reduce((acc, zoneKey) => {
            const [destinationId, ...zoneNameChunks] = zoneKey.split('-')
            if (!acc[destinationId]) {
              acc[destinationId] = []
            }
            acc[destinationId].push(zoneNameChunks.join('-'))
            return acc
          }, {})
        }

        // TODO it would be better if the payload for this search used the getters.getSearchOptions method, to consolidate the way the payload is built in one place (and consolidate with store/charterpackages/index.js actions searchOptions)
        if (this.lastminute) {
          // special case of lastminute
          searchOptions.tripLength = {
            min: 7,
            max: 9
          }
        } else if (this.selectedDateRangeDuration) {
          // case of non-flex dates
          if (this.selectedDateRangeDuration.value === 'custom') {
            // TODO hack edge case where user is redirected to url with duration=n,m, the same computation is in store/charterpackages/util.js l.92 so it would be much better to unify this logic
            searchOptions.tripLength = {
              min: this.selectedDateRangeDuration.custom[0],
              max: this.selectedDateRangeDuration.custom[1],
            }
          } else {
            searchOptions.tripLength = {
              min: this.selectedDateRangeDuration,
              max: this.selectedDateRangeDuration + 2
            }
          }
        } else if (this.selectedDateRangeEndDate) {
          delete searchOptions.tripLength
          // case of flex dates
          // NOTE not using tripLength in this case because departureDate + tripLength !== homeDate
          const daysDiff = dayjs(this.selectedDateRangeEndDate).diff(this.selectedDateRangeStartDate, 'days')
          searchOptions.homeDate = formatDate(dayjs(searchOptions.departureDate.min).add(daysDiff, 'days'))
        }

        this.SET_IS_DIRTY(false)
        this.setHasSearched()

        // if (process.browser) {
        //   if (window.cab_2) {
        //     window.cab_2.abort()
        //   }

        //   window.cab_2 = new AbortController()
        //   abortTimeout(window.cab_2, this.timeout)
        // }

        const { data: result } = await apiFetch(`/${locale}/charter/packages/offers/search`, {
          method: 'POST',
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
          body: searchOptions
          }
        )
        return result
      } catch (e) {
        if (e.name === 'AbortError') {
          return []
        }
      }
    },

    // NOTE use case: general search without constraints
    async searchDealfinder (options) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const charterStore = useCharterStore()
      const destination = charterStore.destination

      this.SET_ERROR(false)
      this.SET_LOADING(true)
      try {
      //   if (process.browser) {
      //     if (window.cab_3) {
      //       window.cab_3.abort()
      //     }
      //     window.cab_3 = new AbortController()
      //     abortTimeout(window.cab_3, this.timeout)
      //   }

        if (destination.packageSearchConditions) {
          options = {
            ...options,
            ...destination.packageSearchConditions
          }
        }

        const { data: result } = await apiFetch(`/${locale}/charter/packages/offers/search`, {
            method: 'POST',
            body: options,
            ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
          }
        )
        // delete window.cab_3

        if (!result.length) {
          this.SET_NOTHINGFOUND(true)
        }
        const transformedResult = result.map((r, i) => ({
          ...r,
          // TODO: temporary fix, refactor arbitrary frontend id and remove it
          trip_package_id: r.id,
          id: `${i}_${r.hotel_code}`
        }))

        this.SET_PACKAGES(transformedResult)
        this.SET_LOADING(false)
        this.SET_IS_DIRTY(false)
        this.setHasSearched()

        return transformedResult
      } catch (e) {
        if (e.name === 'AbortError') {
          return []
        }

        this.SET_ERROR(true)
        return []
      }
    },

    async getState (info) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const { data: result } = await apiFetch(`/${locale}/charter/packages/offers/state`, {
          pick: ['state'],
          method: 'POST',
          body: info
        }, false)

        this.SET_STATEOBJECT({ stateObject: result?.state, step: 0 })

      } catch (e) {
        if (e.data?.message === 'state_expired') {
          this.SET_TIMEDOUT(true)
        }
      }
    },

    async getFlight (info) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const rootStore = useRootStore()
      const charterAirportNames = rootStore.charterAirportNames
      const SET_CHARTER_AIRPORT_NAMES = rootStore.SET_CHARTER_AIRPORT_NAMES

      try {
        const { data: result } = await apiFetch(`/${locale}/charter/packages/offers/flight`, {
          method: 'POST',
          body: info
        })

        const airportNames = {
          ...charterAirportNames
        }

        result.airports.forEach((a) => {
          airportNames[a.iata] = a
        })

        SET_CHARTER_AIRPORT_NAMES(airportNames,)

        return result
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          this.SET_TIMEDOUT(true)
        }

        throw e
      }
    },

    async roomData (info) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      info.state = this.stateObjects[0]
      if(!info.state) {
        return
      }
      try {
        // if (process.browser) {
        //   if (window.cab_4) {
        //     window.cab_4.abort()
        //   }

        //   window.cab_4 = new AbortController()
        //   abortTimeout(window.cab_4, this.timeout)
        // }

        const { data: result } = await apiFetch(`/${locale}/booking/roomtypes-for-hotel`, {
          method: 'POST',
          body: info,
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
        })

        this.SET_STATEOBJECT({ stateObject: result.state, step: 2 })
        this.SET_MODALROOMTYPES(result.roomtypes)
        this.SET_MODALHOTELID(info.hotelid)

        return result
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          this.SET_TIMEDOUT(true)
        }

        throw e
      }
    },

    async hotelInfo(giataId) {
      const charterStore = useCharterStore()
      const getHotelInfo = charterStore.hotelInfo

      return await getHotelInfo(giataId)
    },

    async customerData (data) {
      const bookStore = useBookStore()
      data.state = this.stateObjects[2]

      bookStore.SET_TRAVELERS([])
      bookStore.SET_MANAGER(null)
      bookStore.SET_TRIPINFO({})
      bookStore.SET_ISROUNDTRIP(false)

      try {
        const result = await bookStore.customerData(data)

        return result
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          this.SET_TIMEDOUT(true)
        }

        throw e
      }
    },

    async getCalendarSuggestions (info) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      this.SET_CALENDAR_SUGGESTIONS_FETCH_STATUS(fetchStatus.fetching)
      const { data: result } = await apiFetch(`/${locale}/charter/packages/offers/flight_dates`, {
        method: 'POST',
        body: info
      })

      const calendarSuggestions = {}

      calendarSuggestions.direct = result.direct
      calendarSuggestions.flights = result.flights
      calendarSuggestions.openPurchase = result?.openPurchase
      calendarSuggestions.recommended = result.recommended

      this.SET_CALENDAR_SUGGESTIONS(calendarSuggestions)
      this.SET_CALENDAR_SUGGESTIONS_FETCH_STATUS(fetchStatus.idle)
    },

    async fetchCalendarSuggestionsByDepartureDate (info) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      let _departureDate = info.departureDate || this.selectedDateRangeStartDate

      if (!isDateValid(_departureDate)) {
        return
      }

      if (_departureDate.min) {
        _departureDate = _departureDate.min
      }

      this.SET_CALENDAR_SUGGESTIONS_FETCH_STATUS(fetchStatus.fetching)

      const { data: result } = await apiFetch(`/${locale}/charter/packages/offers/flight_dates/${dayjs(_departureDate).format(DATE_FORMAT)}`, {
        method: 'POST',
        body: {
          ...info,
          homeDate: undefined
        }
      })

      const calendarSuggestions = {}

      calendarSuggestions.direct = result.direct
      calendarSuggestions.flights = result.flights
      calendarSuggestions.openPurchase = result?.openPurchase
      calendarSuggestions.recommended = result.recommended

      this.SET_CALENDAR_SUGGESTIONS_FOR_DEPARTURE_DATE(calendarSuggestions)
      this.SET_CALENDAR_SUGGESTIONS_FETCH_STATUS(fetchStatus.idle)
    },

    async getAdjacentDate (date) {
      const duration = this.getSelectedDuration

      const params = {
        airport: deepClone(this.selectedAirport),
        rooms: deepClone(this.rooms),
        mode: 'dateCalendar',
        minDate: date,
        maxDate: date,
        stopOver: { max: deepClone(this.filterStopover), },
        destinations: deepClone(this.selectedDestinations),
      }

      if (duration.value) {
        params.tripLength = duration.value === 'custom'
          ? { min: +duration.custom[0], max: +duration.custom[1] }
          : { min: duration.value.value, max: duration.value.maxValue }
      }

      const packages = [...this.calendarPackages]
      const emptyDate = {
        loading: true,
        date: params.minDate,
      }
      if (packages.length === 0) {
        packages.push(emptyDate)
      } else {
        const firstDate = this.calendarPackages[0].date
        const lastDate = this.calendarPackages[this.calendarPackages.length - 1].date
        if (date < firstDate) {
          packages.unshift(emptyDate)
        } else if (date > lastDate) {
          packages.push(emptyDate)
        } else {
          //
        }
      }

      this.SET_CALENDAR_PACKAGES(packages)

      try {
        // NOTE grabbing the first result because we're only getting one result
        const result = (await this.searchDate(params))?.[0] || {}

        const date = {
          loading: false,
          active: false,
          price: result.pricepp,
          date: params.minDate,
          dateStart: result.date,
          dateEnd: result.date_end,
          days: result.days,
          destination_id: result.destination_id,
          zone_name: result.zone_name,
          payload: result
        }

        this.SET_CALENDAR_PACKAGE_BY_DATE({ result: date, date: params.minDate })
      } catch (e) {
        this.SET_CALENDAR_PACKAGE_BY_DATE({ result: { loading: false, date: params.minDate } })
      }
    },

    async listFavourites (options) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      this.SET_TIMEDOUT(false)
      this.SET_NOTHINGFOUND(false)
      this.SET_HOTELERROR(false)
      this.SET_MODALHOTELID(null)
      this.SET_MODALROOMTYPES([])
      this.SET_PACKAGES([])
      this.SET_ERROR(false)
      this.SET_LOADING(true)

      try {
        // if (process.browser) {
        //   if (window.cab_5) {
        //     window.cab_5.abort()
        //   }

        //   window.cab_5 = new AbortController()
        //   abortTimeout(window.cab_5, 60 * 1000)
        // }

        let ids = ''
        try {
          ids = JSON.parse(localStorage.getItem(localStorageKeys.zz.favourites))
        } catch {
          // no catch
        }

        const { data: result } = await apiFetch(`/${locale}/charter/packages/offers/search`, {
          method: 'POST',
          body: {
            ids,
            ...options
          },
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
        })

        if (!result.length) {
          this.SET_NOTHINGFOUND(true)
        }

        this.SET_PACKAGES(result.map((r, i) => ({
          ...r,
          search_id: r.id,
          id: `${i}_${r.hotel_code}`
        })))

        this.SET_LOADING(false)
        return result
      } catch (e) {
        if (e.name === 'AbortError') {
          return
        }

        this.SET_ERROR(true)
      }
    },

    async fetchCharterTag (urlName) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      this.SET_LOADING(true)

      try {
        const controller = new AbortController();
        const signal = controller.signal

        const { data: charterTag } = await apiFetch(`/${locale}/charter/tag/${urlName}`,{
          signal
        })
        if (charterTag?.id) {
          this.SET_FILTER_CHARTER_TAGS([charterTag.id])
        }
        return charterTag
      } catch (e) {
        if (e.name === 'AbortError') {
          return
        }

        this.SET_ERROR(true)
      } finally {
        this.SET_LOADING(false)
      }
    },

    handleSearchQueryParams ({ query, customDefaults = {} }) {
      const rootStore = useRootStore()
      const localeStore = useLocaleStore()
      const { localeDateFormats } = useDate()
      const _defaults = {
        ...defaultUrlParams(localeStore.locale),
        stopover: 0,
        ...customDefaults
      }

      const {
        countries,
        categories,
        range,
        destinations,
        date,
        date_end: dateEnd,
        airport,
        duration,
        rating,
        ta,
        tags,
        chartertags,
        text,
        board,
        temperature,
        stopover,
        sortBy
      } = query

      if (countries) {
        this.SET_SELECTED_DESTINATIONSL1(countries.split(','))
      } else if (categories) {
        const selectedCategories = categories.split(',')
        this.SET_SELECTED_CATEGORIES(selectedCategories.filter(categoryId => !isEmpty(categoryId)).map(categoryId => parseInt(categoryId)))
        this.SET_SELECTED_DESTINATIONS(this.destinations.filter(d => d.categories.some((categoryId) => selectedCategories.includes(categoryId))))
      }
      if (!isNaN(parseInt(range))) {
        this.SET_SELECTED_STARTDATERANGE(range)
      }
      if (destinations === 'all') {
        this.SET_SELECTED_DESTINATIONS(deepClone(this.destinations))
      } else if (destinations) {
        this.SET_SELECTED_DESTINATIONS(destinations.split(',').map((name) => {
          return this.destinations.find(d => d.name === name || d.destination_id === +name)
        }))
        if (!categories) {
          this.SET_SELECTED_CATEGORIES([])
        }
      }

      if (date && dayjs(date, localeDateFormats.L, true).isValid()) {
        this.SET_SELECTED_DATE_RANGE_START_DATE(date)
      }

      if (dateEnd && dayjs(dateEnd, localeDateFormats.L, true).isValid()) {
        this.SET_SELECTED_DATE_RANGE_END_DATE(dateEnd)
      }

      if (airport) {
        let _airport = rootStore.charterAirports?.find(ap => ap.airport === airport)

        if (_airport) {
          this.SET_SELECTED_AIRPORT(_airport)
        } else {
          const { defaultAirport } = useLocale()
          _airport = rootStore.charterAirports?.find(ap => ap.airport === defaultAirport.airport)
          if (_airport) {
            this.SET_SELECTED_AIRPORT(_airport)
          }
        }
      }

      if (duration) {
        const durations = duration?.split(',').filter(d => !isNaN(parseInt(d)))

        if (durations && durations.length > 1) {
          // TODO should use the selectedDuration for the non-flex case, merging the two values selectedDuration and selectedDateRangeDuration into one
          this.SET_SELECTED_DURATION({
            value: 'custom',
            custom: durations
          })
          this.SET_SELECTED_DATE_RANGE_DURATION({
            value: 'custom',
            custom: durations
          })
        } else if (durations && durations.length === 1) {
          this.SET_SELECTED_DURATION({
            value: this.durations.find(d => d.value === parseInt(durations[0])),
            custom: [2, 30]
          })
          this.SET_SELECTED_DATE_RANGE_DURATION(parseInt(durations[0]))
        }
      }

      if (range) {
        this.SET_SELECTED_STARTDATERANGE(range)
      }

      if (rating) {
        this.SET_FILTER_RATING(rating?.split(','))
      }

      if (ta) {
        this.SET_FILTER_TRIPADVISOR(ta)
      }

      if (tags) {
        this.SET_FILTER_TAGS(tags.split(','))
      }

      if (chartertags) {
        this.SET_FILTER_CHARTER_TAGS(chartertags.split(',').filter(el => !isNaN(parseInt(el))).map(Number))
      }

      if (text) {
        this.SET_FILTER_TEXT(text)
      }

      if (board) {
        this.SET_FILTER_BOARD(board)
      }

      if (stopover) {
        this.SET_FILTER_STOPOVER(stopover)
      } else {
        this.SET_FILTER_STOPOVER(_defaults.stopover)
      }

      if (temperature) {
        this.SET_FILTER_TEMPERATURE(temperature?.split(','))
      }

      if (sortBy) {
        this.SET_SORT_BY(sortBy)
      }

      const parsedRoomsFromQuery = parseRoomsFromURL(query)

      if (parsedRoomsFromQuery !== null) {
        this.SET_ROOMS(parsedRoomsFromQuery)
      }
    },

    setActiveAdjacentDay(data) {
      return new Promise((resolve, reject) => {
        const { day, duration: queryDuration } = data
        this.SET_ACTIVE_SLIDE_DATE(day.date)

        const date = day.date || this.selectedDateRangeStartDate
        let endDate = this.selectedDateRangeEndDate

        const prevDaysDiff = dayjs(this.selectedDateRangeEndDate)
          .diff(dayjs(this.selectedDateRangeStartDate), 'day')
        const tripDuration = prevDaysDiff || LAST_MINUTE_TRIP_DURATION
        endDate = dayjs(date).add(tripDuration, 'day').format('YYYY-MM-DD')

        let duration = null
        if (this.lastminute) {
          duration = 7
        } else if (queryDuration) {
          duration = queryDuration
        }

        if (this.lastminute) {
          this.deactivateLastMinute(true)
        }
        this.SET_SELECTED_DATE_RANGE_DURATION(duration)
        this.SET_SELECTED_DATE_RANGE_START_DATE(date)
        this.SET_SELECTED_DATE_RANGE_END_DATE(endDate)

        this.search({
          selectedDuration: duration,
          selectedStartDate: date
        })
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deactivateLastMinute (notDates) {
      if (!notDates) {
        this.SET_SELECTED_DATE_RANGE_START_DATE(null)
        this.SET_SELECTED_DATE_RANGE_END_DATE(null)
      }
      this.SET_LASTMINUTE(false)
      this.SET_FILTER_STOPOVER(2)
    },

    /**
     *
     * @param options: { date, airport, destinations, countries, categories }
     */
    activateLastMinute (options) {
      const rootStore = useRootStore()
      const searchWidgetsStore = useSearchWidgetsStore()

      const LAST_MINUTE_START_DATE_OFFSET = 0

      let startDate = dayjs(options?.date)

      if (!options?.date) {
        startDate = dayjs().add(LAST_MINUTE_START_DATE_OFFSET, 'days')
      }

      this.SET_SELECTED_DATE_RANGE_START_DATE(startDate.format(DATEPICKER_DATE_FORMAT))
      this.SET_SELECTED_DATE_RANGE_END_DATE(dayjs(startDate).add(LAST_MINUTE_TRIP_DURATION, 'day').format(DATEPICKER_DATE_FORMAT))
      this.SET_LASTMINUTE(true)
      this.SET_FILTER_STOPOVER(0)
      this.SET_SORT_BY(sortingOptions.priceLow)

      if (options?.airport) {
        this.SET_SELECTED_AIRPORT(options.airport)
      } else {
        const localAirport = useCookie('charterAirport')

        this.SET_SELECTED_AIRPORT(
          localAirport && localAirport?.airport ? localAirport : rootStore.charterAirports[0]
        )
      }

      if (!(options?.destinations || options?.countries || options?.categories)) {
        if (this.categories?.[0]) {
          searchWidgetsStore.handleCategories([this.categories[0]])
        }
        this.SET_SELECTED_CATEGORIES([searchWidgetsStore.destinations.stagedCategories[0]?.id])
        this.SET_SELECTED_DESTINATIONS(searchWidgetsStore.destinations.stagedDestinations)
      }
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCharterPackagesStore, import.meta.hot))
}